import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import { Slide } from "./components/Slide";
import { Programme } from "./interfaces/Programme.interface";

const GenererVideo = () => {
  const [programmes, setProgrammes] = useState<Programme[]>([]);
  const [slideActuelle, setSlideActuelle] = useState<number>(0);
  const [estAffiche, setEstAffiche] = useState<boolean>(false);
  const [diaporamaId, setDiaporamaId] = useState<number>();

  // On récupère les programmes
  const recupererProgrammes = async () => {
    const response = await fetch("https://admin.agti.immo/api_no_auth/programme/filters", {
      method: "POST",
    });
    const data: Programme[] = await response.json();
    setProgrammes(data);
  };

  const creerDiaporama = async () => {
    const nomDiaporama = `Diaporama_${new Date().getTime()}`;
    const response = await fetch(`https://admin.agti.immo/api_no_auth/diaporama/creer/${nomDiaporama}` , {
      method: "POST",
    });
    const data = await response.json();
    setDiaporamaId(data);
  }

  // On cache la slide, on attend que la transition soit terminée et ensuite on change de slide
  const nextSlide = async () => {
    if (slideActuelle < programmes.length - 1) {
      setSlideActuelle((current) => current + 1);
    } else {
      setSlideActuelle(0);
    }
  };

  // On cache la slide, on attend que la transition soit terminée et ensuite on change de slide
  const previousSlide = async () => {
    if (slideActuelle > 0) {
      setSlideActuelle((current) => current - 1);
    } else {
      setSlideActuelle(programmes.length - 1);
    }
  };

  const ajouterToutesImages = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    for(let i = 0; i < programmes.length; i++) {
      await ajouterImage();
      nextSlide();
      console.log(`Image ${i+1}/${programmes.length} ajoutée`);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    console.log("Toutes les images ont été ajoutées");
    redirectToAdminAgti();
  }


  const ajouterImage = async () => {
    const image = await genererImage();

    if (image) {
      const blob = await fetch(image).then((r) => r.blob());

      const formData = new FormData();
      formData.append("fichier", blob, "image.png");

      const response = await fetch(`https://admin.agti.immo/api_no_auth/diaporama/attachment/${diaporamaId}/ajouter`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      return data;
    }
  }

  const genererImage = async () => {
    const canvas = await html2canvas(document.body, {
      useCORS: true,
      allowTaint: true
    });
    return canvas.toDataURL("image/png");
  }

  const redirectToAdminAgti = () => {
    window.location.href = `https://admin.agti.immo/admin?crudAction=createDiaporamaTransition&crudControllerFqcn=App%5CController%5CAdmin%5CDiaporamaCrudController&entityId=${diaporamaId}&menuIndex=11&submenuIndex=-1`;
  }

  // On récupère les programmes au chargement de la page
  useEffect(() => {
    recupererProgrammes();
    creerDiaporama();
  }, [])

  useEffect(() => {
    if (programmes.length > 0 && diaporamaId) {
      ajouterToutesImages();
    }
  }, [programmes, diaporamaId])

  return (
    <div id="div_imprimer" className="w-full h-full">
      {programmes.length > 0 && (
        <Slide
          programme={programmes[slideActuelle]}
          estAffiche={estAffiche}
          animations={false}
        >
          
        </Slide>
      )}
    </div>
  );
}

export default GenererVideo;