import { Transition } from "@headlessui/react";
import { Programme } from "../interfaces/Programme.interface";
import { BadgeEtat } from "./BadgeEtat";

interface Props {
  programme: Programme;
  estAffiche: boolean;
  animations: boolean;
  children?: React.ReactNode;
}

export const Slide = ({ programme, estAffiche, animations, children }: Props) => {
  return (
    <div className="w-full h-screen">
      <div className="flex flex-col gap-6 mt-2">
        <img
          src="LogoAGTI.jpeg"
          alt="Logo AGTI"
          className="mx-auto h-32"
        />
        <h1 className="mx-auto text-4xl font-bold text-white">Notre partenaire foncier</h1>
      </div>

      {animations ? (
        <Transition
          show={estAffiche}
          enter={"transition-opacity duration-[1000ms]"}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-[1000ms]"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex gap-5 m-10 mt-14 mx-14">
            <img
              src={`https://admin.agti.immo/api_no_auth/image/${programme.entity.id}`}
              alt={programme.PROGRAMME}
              className="rounded-xl w-[45%] max-h-[40rem]"
            />

            <table className="w-[55%] h-fit max-h-[40rem] bg-white rounded-xl">
              <thead>
                <tr className="bg-blue-400">
                  <th colSpan={4} className="p-3 rounded-t-xl text-center">
                    <h3 className="text-2xl">{programme.PROGRAMME}</h3>
                    <h3 className="text-2xl">{programme.entity.adresse} {programme.entity.codePostal}</h3>
                  </th>
                </tr>

                <tr className="bg-blue-200">
                  <th className="p-3 text-center">
                    <h4>Lot</h4>
                  </th>
                  <th className="p-3 text-center">
                    <h4>Superficie</h4>
                  </th>
                  <th className="p-3 text-center">
                    <h4>Prix</h4>
                  </th>
                  <th className="p-3 text-center">
                    <h4>Etat</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {programme.lots.map((lot) => (
                  <tr key={lot.ID}>
                    <td className="p-2 text-center">
                      <h4>{lot.NUMERO}</h4>
                    </td>
                    <td className="p-2 text-center">
                      <h4>{lot.SURFACE_TERRAIN} m²</h4>
                    </td>
                    <td className="p-2 text-center">
                      <h4>{parseFloat(lot.PRIXTTC).toLocaleString("fr")} €</h4>
                    </td>
                    <td className="p-2">
                      <BadgeEtat etat={lot.ETAT}/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Transition>
      ) : (
        <div className="flex gap-5 m-10 mt-14 mx-14">
          <img
            src={`https://admin.agti.immo/api_no_auth/image/${programme.entity.id}`}
            alt={programme.PROGRAMME}
            className="rounded-xl w-[45%] max-h-[40rem]"
          />

          <table className="w-[55%] h-fit max-h-[40rem] bg-white rounded-xl">
            <thead>
              <tr className="bg-blue-400">
                <th colSpan={4} className="p-3 rounded-t-xl text-center">
                  <h3 className="text-2xl">{programme.PROGRAMME}</h3>
                  <h3 className="text-2xl">{programme.entity.adresse} {programme.entity.codePostal}</h3>
                </th>
              </tr>

              <tr className="bg-blue-200">
                <th className="p-3 text-center">
                  <h4>Lot</h4>
                </th>
                <th className="p-3 text-center">
                  <h4>Superficie</h4>
                </th>
                <th className="p-3 text-center">
                  <h4>Prix</h4>
                </th>
                <th className="p-3 text-center">
                  <h4>Etat</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              {programme.lots.map((lot) => (
                <tr key={lot.ID}>
                  <td className="p-2 text-center">
                    <h4>{lot.NUMERO}</h4>
                  </td>
                  <td className="p-2 text-center">
                    <h4>{lot.SURFACE_TERRAIN} m²</h4>
                  </td>
                  <td className="p-2 text-center">
                    <h4>{parseFloat(lot.PRIXTTC).toLocaleString("fr")} €</h4>
                  </td>
                  <td className="p-2">
                    <BadgeEtat etat={lot.ETAT}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {children}
    </div>
  )
}