import { useEffect, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { Slide } from "./components/Slide";
import { Programme } from "./interfaces/Programme.interface";

const App = () => {
  const [programmes, setProgrammes] = useState<Programme[]>([]);
  const [slideActuelle, setSlideActuelle] = useState<number>(0);
  const [estAffiche, setEstAffiche] = useState<boolean>(false);

  // Temps de transition entre les slides
  const tempsTransition = 1000;

  // On récupère les programmes
  const recupererProgrammes = async () => {
    const response = await fetch("https://admin.agti.immo/api_no_auth/programme/filters", {
      method: "POST",
    });
    const data: Programme[] = await response.json();
    setProgrammes(data);
  };

  // On cache la slide, on attend que la transition soit terminée et ensuite on change de slide
  const nextSlide = async () => {
    await cacherSlide();
    if (slideActuelle < programmes.length - 1) {
      setSlideActuelle((current) => current + 1);
    } else {
      setSlideActuelle(0);
    }
  };

  // On cache la slide, on attend que la transition soit terminée et ensuite on change de slide
  const previousSlide = async () => {
    await cacherSlide();
    if (slideActuelle > 0) {
      setSlideActuelle((current) => current - 1);
    } else {
      setSlideActuelle(programmes.length - 1);
    }
  };

  // On cache la slide et on attend que la transition soit terminée
  const cacherSlide = async () => {
    setEstAffiche(false);
    await new Promise((resolve) => setTimeout(resolve, tempsTransition));
  };

  // On récupère les programmes au chargement de la page
  useEffect(() => {
    recupererProgrammes();
  }, [])

  // On change de slide toutes les 5 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 10000);

    return () => clearInterval(interval);
  }, [programmes, slideActuelle])

  // On affiche la slide après avoir changé de slide
  useEffect(() => {
    setEstAffiche(true);
  }, [slideActuelle])

  return (
    <div className="w-full h-full">
      {programmes.length > 0 && (
        <Slide
          key={programmes[slideActuelle].ID}
          programme={programmes[slideActuelle]}
          estAffiche={estAffiche}
          animations={true}
        >
          <div
            className="absolute top-[45%] text-2xl left-1 rounded-full p-2 bg-black/20 hover:bg-slate-400/20 text-white cursor-pointer"
            onClick={previousSlide}
          >
            <BsChevronCompactLeft size={30}/>
          </div>

          <div
            className="absolute top-[45%] right-1 text-2xl rounded-full p-2 bg-black/20 hover:bg-slate-400/20 text-white cursor-pointer"
            onClick={nextSlide}
          >
            <BsChevronCompactRight size={30}/>
          </div>
        </Slide>
      )}
    </div>
  );
}

export default App;