interface Parametres {
  etat: string;
}

export const BadgeEtat = ({ etat }: Parametres) => {
  return (
    <>
      {etat === "LIBRE" && (
        <div className="rounded-lg text-center mx-auto w-fit bg-yellow-500">
          <h5 className="m-1 font-bold text-sm">LIBRE</h5>
        </div>
      )}
      {etat === "OPTION" && (
        <div className="rounded-lg text-center mx-auto w-fit bg-statut_option">
          <h5 className="m-1 font-bold text-white">OPTION</h5>
        </div>
      )}
      {etat === "RESERVATION" && (
        <div className="rounded-lg text-center mx-auto w-fit bg-green-500">
          <h5 className="m-1 font-bold text-white">RESERVATION</h5>
        </div>
      )}
    </>
  )
}